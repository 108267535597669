import { React, useState } from "react";
import "./Components/Dashboard.css"; // Import the CSS file
import {
  StandardCardCollection,
  FundedVerticalBarCard,
  DetailsCard,
  Donutchart,
  WelcomeCard,
} from "./ui-components";
// import { Flex } from "@aws-amplify/ui-react";

const HomePage = () => {
  const [selection, setSelection] = useState();
  console.log(selection);

  return (
    <div>
      <div className="master-container">
        {/* <div className="portfolio"> */}
        {/* <Flex overflow="scroll"> */}
        <WelcomeCard />
        <div className="stats">
          <h3>Your Funds</h3>
          <StandardCardCollection
            overrideItems={({ item }) => ({
              onClick: () => setSelection(item),
            })}
          />
          {selection ? (
            <DetailsCard funds={selection.fund_name} />
          ) : (
            <h1>nothing to display here</h1>
          )}
        </div>
        <h4>Funded %</h4>
        <FundedVerticalBarCard style={{ width: "100vw" }} />
        <h4>Industry Exposure</h4>
        <Donutchart />
        {/* </Flex> */}
      </div>
    </div>
    // </div>
  );
};

export default HomePage;
