/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Text, View } from "@aws-amplify/ui-react";
export default function WelcomeCard(props) {
  const { reports, investors, overrides, ...rest } = props;
  return (
    <Flex
      gap="17px"
      direction="column"
      width="479px"
      height="136px"
      justifyContent="flex-start"
      alignItems="flex-end"
      position="relative"
      borderRadius="8px"
      padding="8px 16px 8px 16px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "WelcomeCard")}
      {...rest}
    >
      <View
        width="unset"
        height="80px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Body")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="80px"
          height="80px"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          top="0px"
          left="0px"
          borderRadius="64px"
          padding="7px 6px 7px 6px"
          backgroundColor="rgba(217,217,217,1)"
          {...getOverrideProps(overrides, "Frame 1")}
        >
          <Text
            fontFamily="Inter"
            fontSize="36px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={reports?.initials}
            {...getOverrideProps(overrides, "GK")}
          ></Text>
        </Flex>
        <Flex
          gap="8px"
          direction="column"
          width="351px"
          height="80px"
          justifyContent="flex-start"
          alignItems="flex-start"
          position="absolute"
          top="0px"
          left="96px"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame29766879")}
        >
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame29766880")}
          >
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame29766881")}
            >
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={reports?.report_name}
                {...getOverrideProps(overrides, "Scott")}
              ></Text>
            </Flex>
            <Flex
              gap="0"
              direction="row"
              width="292px"
              height="unset"
              justifyContent="flex-end"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame29766885")}
            ></Flex>
          </Flex>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(13,26,38,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={investors?.investor_name}
            {...getOverrideProps(
              overrides,
              "\u201CLorem ipsum dolor sit amet, consectetur adipiscing elit. \u201D"
            )}
          ></Text>
        </Flex>
      </View>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="800"
        color="rgba(0,0,0,1)"
        textTransform="uppercase"
        lineHeight="24px"
        textAlign="right"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.01px"
        width="unset"
        height="31px"
        gap="unset"
        alignItems="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={reports?.type}
        {...getOverrideProps(overrides, "Investor Type")}
      ></Text>
    </Flex>
  );
}
