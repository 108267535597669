// ProtectedRoute.js
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Auth } from "aws-amplify";

const ProtectedRoute = ({ requiredGroups, children }) => {
  const [hasAccess, setHasAccess] = useState(null);
  console.log("hasAccess:", hasAccess);

  useEffect(() => {
    const checkUserGroup = async () => {
      try {
        const session = await Auth.currentSession();
        const idToken = session.getIdToken();
        const userGroups = idToken.payload["cognito:groups"] || [];
        console.log("userGroups:", userGroups);

        const isAuthorized = requiredGroups.some((group) =>
          userGroups.includes(group)
        );
        setHasAccess(isAuthorized);
      } catch {
        setHasAccess(false);
      }
    };

    checkUserGroup();
  }, [requiredGroups]);

  if (hasAccess === null) {
    // Optionally render a loading indicator
    return <div>Loading...</div>;
  }

  return hasAccess ? children : <Navigate to="/" replace />;
};

export default ProtectedRoute;
