// ClientApp.js
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import HomePage from "./HomePage Investor";
// import FundExplorer from "./FundExplorer";
// import DocumentsPage from "./DocumentsPage";
import Settings from "./Components/Settings";

function ClientApp() {
  return (
    <div className={`d-flex custom-body`} id="wrapper">
      <div>
        <Routes>
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/fund-explorer"
            element={
              <PrivateRoute>
                <FundExplorer />
              </PrivateRoute>
            }
          /> */}
          {/* <Route
            path="/documents"
            element={
              <PrivateRoute>
                <DocumentsPage />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default ClientApp;
