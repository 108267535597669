/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon, Text, View } from "@aws-amplify/ui-react";
export default function Donutchart(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="530.75px"
      height="318.45px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "Donutchart")}
      {...rest}
    >
      <View
        width="530.75px"
        height="318.45px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Rectangle")}
      ></View>
      <Icon
        width="125.03px"
        height="142.2px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 125.02546691894531,
          height: 142.19529724121094,
        }}
        paths={[
          {
            d: "M0 2.59301e-06C17.8701 -0.00363707 35.5332 3.82436 51.7985 11.2259C68.0637 18.6274 82.5529 29.4303 94.2891 42.9063C106.025 56.3823 114.736 72.2181 119.833 89.3458C124.93 106.474 126.295 124.495 123.837 142.195L37.1689 130.159C37.9064 124.849 37.4968 119.442 35.9676 114.304C34.4385 109.165 31.8254 104.415 28.3045 100.372C24.7837 96.3291 20.4369 93.0882 15.5574 90.8678C10.6778 88.6473 5.37885 87.4989 0.0178223 87.5L0 2.59301e-06Z",
            fill: "rgba(54,94,61,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="16.64%"
        bottom="38.7%"
        left="53.41%"
        right="23.03%"
        {...getOverrideProps(overrides, "Pie3901982")}
      ></Icon>
      <Icon
        width="139.4px"
        height="119.8px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 139.40396118164062,
          height: 119.80474090576172,
        }}
        paths={[
          {
            d: "M139.404 12.1234C134.852 44.6599 117.674 74.0963 91.5855 94.0648C65.4972 114.033 32.5962 122.928 0 118.826L10.9267 32.0107C20.7056 33.2415 30.5759 30.573 38.4024 24.5824C46.2289 18.5919 51.3823 9.76095 52.7479 0L139.404 12.1234Z",
            fill: "rgba(125,214,232,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="57.53%"
        bottom="4.85%"
        left="50.47%"
        right="23.26%"
        {...getOverrideProps(overrides, "Pie3901984")}
      ></Icon>
      <Icon
        width="114.71px"
        height="112.94px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 114.71200561523438,
          height: 112.93844604492188,
        }}
        paths={[
          {
            d: "M103.698 112.938C79.8711 109.915 57.4192 100.091 39.0289 84.6422C20.6386 69.1929 7.08857 48.7725 0 25.824L83.6025 0C85.729 6.88456 89.794 13.0107 95.3111 17.6455C100.828 22.2802 107.564 25.2274 114.712 26.1343L103.698 112.938Z",
            fill: "rgba(149,4,149,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="59.37%"
        bottom="5.16%"
        left="30.91%"
        right="47.47%"
        {...getOverrideProps(overrides, "Pie3901986")}
      ></Icon>
      <Icon
        width="90.39px"
        height="84.92px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 90.39353942871094,
          height: 84.91993713378906,
        }}
        paths={[
          {
            d: "M5.5311 84.9199C-3.06187 57.0021 -1.6063 26.9565 9.64507 0L90.3935 33.7036C87.0181 41.7905 86.5815 50.8042 89.1593 59.1796L5.5311 84.9199Z",
            fill: "rgba(77,0,77,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="40.78%"
        bottom="32.56%"
        left="29.86%"
        right="53.11%"
        {...getOverrideProps(overrides, "Pie3901988")}
      ></Icon>
      <Icon
        width="92.87px"
        height="85.68px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 92.87017822265625,
          height: 85.67918395996094,
        }}
        paths={[
          {
            d: "M0 51.8948C8.6259 31.2866 22.6173 13.3666 40.5181 0L92.8702 70.1107C87.4999 74.1207 83.3025 79.4967 80.7147 85.6792L0 51.8948Z",
            fill: "rgba(4,125,149,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="24.44%"
        bottom="48.65%"
        left="31.69%"
        right="50.81%"
        {...getOverrideProps(overrides, "Pie3901990")}
      ></Icon>
      <Icon
        width="63.38px"
        height="89.11px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 63.37776184082031,
          height: 89.10874938964844,
        }}
        paths={[
          {
            d: "M0 18.9457C11.1853 10.611 23.6867 4.2073 36.9862 0L63.3778 83.425C59.3879 84.6872 55.6375 86.6083 52.2819 89.1087L0 18.9457Z",
            fill: "rgba(0,64,77,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="18.47%"
        bottom="53.55%"
        left="39.34%"
        right="48.72%"
        {...getOverrideProps(overrides, "Pie3901992")}
      ></Icon>
      <Icon
        width="35.39px"
        height="89.02px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 35.39466857910156,
          height: 89.02210235595703,
        }}
        paths={[
          {
            d: "M0 5.57072C9.82797 2.47245 20.0014 0.601343 30.2886 0L35.3947 87.3509C32.3085 87.5313 29.2565 88.0926 26.3081 89.0221L0 5.57072Z",
            fill: "rgba(54,94,61,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="16.71%"
        bottom="55.34%"
        left="46.33%"
        right="47%"
        {...getOverrideProps(overrides, "Pie3901994")}
      ></Icon>
      <Icon
        width="6.78px"
        height="87.55px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 6.78009033203125,
          height: 87.55499267578125,
        }}
        paths={[
          {
            d: "M0 0.199043C1.9552 0.086713 3.91278 0.0203457 5.87109 0L6.78009 87.4953C6.1926 87.5014 5.60533 87.5213 5.01877 87.555L0 0.199043Z",
            fill: "rgba(125,214,232,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="16.65%"
        bottom="55.86%"
        left="52.06%"
        right="46.66%"
        {...getOverrideProps(overrides, "Pie3901996")}
      ></Icon>
      <Icon
        width="1.12px"
        height="87.5px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 1.12152099609375,
          height: 87.50152587890625,
        }}
        paths={[
          {
            d: "M0 0.00538635C0.333319 0.00225673 0.666667 0.000462994 1 0L1.12152 87.4999C1.02152 87.5 0.921529 87.5006 0.821533 87.5015L0 0.00538635Z",
            fill: "rgba(149,4,149,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="16.64%"
        bottom="55.88%"
        left="53.19%"
        right="46.59%"
        {...getOverrideProps(overrides, "Pie3901998")}
      ></Icon>
      <Icon
        width="27.64px"
        height="19.69px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 27.636810302734375,
          height: 19.686920166015625,
        }}
        paths={[
          {
            d: "M27.6368 -0.5C26.8866 -0.5 25.895 -0.14325 24.7936 0.397015C23.6701 0.948063 22.3565 1.72933 20.9357 2.65748C18.0927 4.51471 14.7813 6.98575 11.6351 9.44982C8.48716 11.9153 5.49673 14.3799 3.2938 16.2278C2.19217 17.1518 1.28709 17.9219 0.657257 18.4612C0.342333 18.7308 0.0962018 18.9427 -0.0713008 19.0874C-0.155053 19.1597 -0.219149 19.2151 -0.262361 19.2526C-0.283967 19.2713 -0.300352 19.2855 -0.311362 19.2951C-0.316867 19.2999 -0.321029 19.3035 -0.323827 19.3059C-0.325227 19.3071 -0.326285 19.308 -0.327001 19.3087C-0.327358 19.309 -0.327637 19.3092 -0.327816 19.3094C-0.328009 19.3095 -0.328115 19.3096 0 19.6869C0.328115 20.0642 0.328182 20.0641 0.328334 20.064C0.328499 20.0639 0.328738 20.0637 0.329068 20.0634C0.32973 20.0628 0.330734 20.0619 0.332079 20.0608C0.334768 20.0584 0.33882 20.0549 0.344214 20.0502C0.355003 20.0408 0.371163 20.0268 0.39254 20.0083C0.435296 19.9713 0.49892 19.9162 0.582186 19.8443C0.748719 19.7005 0.993809 19.4895 1.30763 19.2208C1.93527 18.6834 2.83776 17.9155 3.93645 16.9939C6.13418 15.1505 9.11545 12.6934 12.2517 10.2371C15.3897 7.77944 18.675 5.32875 21.4826 3.49468C22.8871 2.57718 24.1624 1.82041 25.2339 1.29483C26.3274 0.758466 27.137 0.5 27.6368 0.5L27.6368 -0.5Z",
            stroke: "rgba(54,94,61,1)",
            fillRule: "nonzero",
            strokeWidth: 1,
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="23.95%"
        bottom="69.86%"
        left="71.19%"
        right="23.61%"
        {...getOverrideProps(overrides, "Connector39011000")}
      ></Icon>
      <Icon
        width="23.22px"
        height="23.83px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 23.221649169921875,
          height: 23.832122802734375,
        }}
        paths={[
          {
            d: "M23.2216 23.3321C22.758 23.3321 22.0355 23.0391 21.0658 22.3922C20.1204 21.7615 19.0205 20.8505 17.8237 19.7416C15.432 17.5255 12.6993 14.5623 10.1131 11.588C7.52895 8.61589 5.09961 5.64257 3.31578 3.41153C2.42403 2.29622 1.694 1.36691 1.18706 0.716626C0.933598 0.391494 0.735927 0.136144 0.601721 -0.0377926C0.534619 -0.12476 0.483385 -0.191372 0.448978 -0.236174C0.431775 -0.258576 0.418779 -0.275524 0.410109 -0.286838C0.405775 -0.292495 0.402522 -0.296743 0.400366 -0.29956C0.399287 -0.300969 0.398484 -0.302019 0.397956 -0.302709C0.397692 -0.303054 0.397504 -0.303301 0.397371 -0.303473C0.397252 -0.303629 0.397202 -0.303694 0 0C-0.397202 0.303694 -0.397113 0.303811 -0.396955 0.304018C-0.396809 0.304208 -0.396582 0.304506 -0.396291 0.304886C-0.395711 0.305645 -0.394854 0.306764 -0.393723 0.308242C-0.39146 0.311198 -0.3881 0.315587 -0.383657 0.321385C-0.374772 0.332981 -0.361556 0.350215 -0.34413 0.372907C-0.309277 0.418289 -0.257582 0.4855 -0.190004 0.573084C-0.0548498 0.748251 0.143843 1.00492 0.398394 1.33145C0.90748 1.98448 1.64008 2.91705 2.53474 4.03601C4.32371 6.27349 6.76229 9.2582 9.35851 12.2441C11.9528 15.2278 14.713 18.2226 17.1441 20.4751C18.3587 21.6005 19.5026 22.5514 20.5108 23.224C21.4946 23.8804 22.4353 24.3321 23.2216 24.3321L23.2216 23.3321Z",
            stroke: "rgba(125,214,232,1)",
            fillRule: "nonzero",
            strokeWidth: 1,
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="87.08%"
        bottom="5.44%"
        left="67.72%"
        right="27.9%"
        {...getOverrideProps(overrides, "Connector39011002")}
      ></Icon>
      <Icon
        width="24.31px"
        height="22.96px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 24.306182861328125,
          height: 22.962371826171875,
        }}
        paths={[
          {
            d: "M0 23.4624C0.778022 23.4624 1.73198 23.0317 2.74461 22.3999C3.78131 21.7531 4.96712 20.8382 6.23249 19.7542C8.76497 17.5849 11.6608 14.7001 14.3907 11.8255C17.1225 8.94883 19.6967 6.07327 21.5874 3.91756C22.5329 2.83951 23.3079 1.94104 23.8466 1.31188C24.116 0.997296 24.3264 0.750019 24.4695 0.581267C24.541 0.49689 24.5958 0.432143 24.6327 0.388426C24.6511 0.366568 24.6651 0.349968 24.6746 0.3388C24.6793 0.333216 24.6828 0.32899 24.6852 0.326145C24.6864 0.324722 24.6873 0.323644 24.6879 0.322914C24.6882 0.322549 24.6885 0.322263 24.6886 0.32208C24.6888 0.321881 24.6889 0.32177 24.3062 0C23.9235 -0.32177 23.9234 -0.321706 23.9233 -0.321555C23.9232 -0.321388 23.923 -0.32115 23.9227 -0.320817C23.9221 -0.320151 23.9213 -0.319138 23.9201 -0.317779C23.9178 -0.315063 23.9144 -0.310967 23.9098 -0.305514C23.9006 -0.294608 23.8868 -0.278273 23.8686 -0.256684C23.8321 -0.213507 23.7779 -0.149315 23.7068 -0.0655103C23.5647 0.102101 23.3554 0.348156 23.0871 0.661447C22.5505 1.28805 21.7781 2.18351 20.8356 3.25818C18.9501 5.40792 16.3853 8.27295 13.6655 11.1369C10.9439 14.0028 8.07562 16.8587 5.58194 18.9948C4.33423 20.0636 3.19143 20.9425 2.2153 21.5515C1.21509 22.1755 0.471978 22.4624 0 22.4624L0 23.4624Z",
            stroke: "rgba(149,4,149,1)",
            fillRule: "nonzero",
            strokeWidth: 1,
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="85.94%"
        bottom="6.85%"
        left="33.68%"
        right="61.74%"
        {...getOverrideProps(overrides, "Connector39011004")}
      ></Icon>
      <Icon
        width="34.96px"
        height="1.46px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 34.96440124511719,
          height: 1.46112060546875,
        }}
        paths={[
          {
            d: "M0 0.5C2.48554 0.5 11.2111 0.864436 19.3347 1.23005C23.3922 1.41267 27.2936 1.59529 30.1806 1.73226C31.6241 1.80074 32.814 1.85781 33.643 1.89776C34.0575 1.91773 34.3818 1.93343 34.6024 1.94412C34.7128 1.94947 34.7972 1.95358 34.854 1.95634C34.8824 1.95772 34.9039 1.95877 34.9183 1.95947C34.9255 1.95982 34.931 1.96009 34.9346 1.96026C34.9364 1.96035 34.9378 1.96042 34.9387 1.96046C34.9391 1.96048 34.9395 1.9605 34.9397 1.96051C34.9399 1.96052 34.94 1.96053 34.9644 1.46112C34.9888 0.961714 34.9886 0.961708 34.9884 0.961697C34.9882 0.961686 34.9878 0.961669 34.9874 0.961647C34.9865 0.961602 34.9851 0.961536 34.9833 0.961447C34.9796 0.96127 34.9742 0.961005 34.967 0.960653C34.9526 0.959951 34.931 0.958902 34.9026 0.957519C34.8457 0.954754 34.7612 0.95065 34.6509 0.945298C34.4301 0.934595 34.1058 0.918896 33.6912 0.898918C32.862 0.85896 31.6718 0.801879 30.228 0.733382C27.3405 0.59639 23.4383 0.41373 19.3797 0.231066C11.2711 -0.133876 2.51446 -0.5 0 -0.5L0 0.5Z",
            stroke: "rgba(77,0,77,1)",
            fillRule: "nonzero",
            strokeWidth: 1,
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="53.53%"
        bottom="46.02%"
        left="23.3%"
        right="70.11%"
        {...getOverrideProps(overrides, "Connector39011006")}
      ></Icon>
      <Icon
        width="53.95px"
        height="29.17px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 53.95442199707031,
          height: 29.170997619628906,
        }}
        paths={[
          {
            d: "M0 29.671C0.401643 29.671 0.953977 29.5317 1.58976 29.3172C2.24254 29.0969 3.0397 28.7771 3.95487 28.3754C5.7862 27.5715 8.11943 26.4265 10.7693 25.0572C16.0706 22.318 22.6653 18.6678 29.0988 15.0205C35.5334 11.3725 41.8111 7.72486 46.4799 4.98937C48.8144 3.62158 50.7468 2.48173 52.0957 1.68378C52.7702 1.28481 53.2987 0.9713 53.6587 0.757511C53.8387 0.650616 53.9766 0.568649 54.0695 0.513392C54.1159 0.485763 54.1511 0.464811 54.1747 0.450759C54.1865 0.443733 54.1954 0.438432 54.2014 0.434884C54.2043 0.43311 54.2066 0.431774 54.2081 0.430879C54.2088 0.430432 54.2094 0.430094 54.2098 0.42987C54.2102 0.429642 54.2104 0.429525 53.9544 0C53.6985 -0.429525 53.6983 -0.429419 53.6979 -0.429203C53.6976 -0.428983 53.697 -0.428655 53.6963 -0.428216C53.6948 -0.427337 53.6926 -0.426016 53.6896 -0.424257C53.6837 -0.420739 53.6749 -0.415468 53.6631 -0.408473C53.6396 -0.394483 53.6045 -0.373594 53.5582 -0.346029C53.4655 -0.290898 53.3279 -0.209063 53.1481 -0.102303C52.7886 0.111217 52.2605 0.424433 51.5866 0.823102C50.2387 1.62044 48.3075 2.75958 45.9744 4.12656C41.308 6.86064 35.0347 10.5057 28.6056 14.1505C22.1755 17.7959 15.5941 21.4385 10.3102 24.1688C7.66748 25.5344 5.35582 26.6683 3.55293 27.4597C2.65099 27.8556 1.88369 28.1626 1.27005 28.3696C0.639417 28.5824 0.223357 28.671 0 28.671L0 29.671Z",
            stroke: "rgba(4,125,149,1)",
            fillRule: "nonzero",
            strokeWidth: 1,
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="31.73%"
        bottom="59.11%"
        left="24.69%"
        right="65.14%"
        {...getOverrideProps(overrides, "Connector39011008")}
      ></Icon>
      <Icon
        width="83.95px"
        height="36.69px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 83.95425415039062,
          height: 36.692222595214844,
        }}
        paths={[
          {
            d: "M0 37.1922C0.216339 37.1922 0.491033 37.145 0.794911 37.0738C1.10759 37.0004 1.48413 36.8935 1.91708 36.757C2.78343 36.4841 3.8982 36.086 5.22073 35.5829C7.86699 34.5763 11.3681 33.1401 15.4226 31.4193C23.5328 27.9773 33.8748 23.3889 44.0575 18.802C54.2409 14.2147 64.2677 9.62764 71.7484 6.18743C75.4889 4.4673 78.5929 3.03385 80.7619 2.03041C81.8463 1.52869 82.6971 1.13447 83.2767 0.865668C83.5665 0.731267 83.7886 0.62822 83.9382 0.558767C84.013 0.524041 84.0696 0.497712 84.1076 0.480063C84.1266 0.471238 84.141 0.464582 84.1506 0.460132C84.1553 0.457906 84.1589 0.456232 84.1613 0.455113C84.1626 0.454554 84.1635 0.454132 84.1641 0.453852C84.1647 0.45357 84.165 0.453428 83.9543 0C83.7435 -0.453428 83.7432 -0.45329 83.7426 -0.453014C83.742 -0.452735 83.7411 -0.452319 83.74 -0.451763C83.7376 -0.450651 83.734 -0.448983 83.7292 -0.446764C83.7196 -0.442327 83.7053 -0.435685 83.6864 -0.426874C83.6484 -0.409252 83.5918 -0.382951 83.5171 -0.348253C83.3676 -0.278856 83.1457 -0.175868 82.856 -0.0415266C82.2766 0.227155 81.4262 0.621246 80.342 1.12283C78.1736 2.126 75.0703 3.55913 71.3306 5.27889C63.8512 8.71847 53.827 13.3044 43.6468 17.8902C33.4659 22.4763 23.1319 27.0611 15.0319 30.4988C10.9813 32.2179 7.49377 33.6483 4.86518 34.6483C3.55029 35.1485 2.45609 35.5387 1.61656 35.8033C1.19658 35.9356 0.845958 36.0347 0.566582 36.1002C0.278409 36.1678 0.0961612 36.1922 0 36.1922L0 37.1922Z",
            stroke: "rgba(0,64,77,1)",
            fillRule: "nonzero",
            strokeWidth: 1,
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="20.95%"
        bottom="67.53%"
        left="26.87%"
        right="57.31%"
        {...getOverrideProps(overrides, "Connector39011010")}
      ></Icon>
      <Icon
        width="99.68px"
        height="21.55px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 99.67581176757812,
          height: 21.54833984375,
        }}
        paths={[
          {
            d: "M0 22.0483C0.360784 22.0483 1.06577 21.956 2.02383 21.7998C2.99866 21.6409 4.27727 21.4084 5.81281 21.1135C8.88456 20.5234 12.9964 19.6809 17.7869 18.6707C27.3685 16.65 39.6737 13.956 51.8211 11.2623C63.9689 8.56859 75.9603 5.8749 84.9146 3.85467C89.3918 2.84455 93.1098 2.00277 95.7085 1.41353C97.0079 1.1189 98.0274 0.887412 98.7221 0.729572C99.0695 0.650652 99.3356 0.590145 99.5149 0.549367C99.6046 0.528978 99.6726 0.513521 99.7181 0.50316C99.7409 0.49798 99.758 0.494073 99.7695 0.491462C99.7752 0.490156 99.7796 0.489174 99.7824 0.488518C99.7839 0.48819 99.785 0.487944 99.7857 0.487779C99.7864 0.487615 99.7868 0.487532 99.6758 0C99.5648 -0.487532 99.5645 -0.48745 99.5638 -0.487287C99.563 -0.487123 99.562 -0.486878 99.5605 -0.48655C99.5577 -0.485896 99.5534 -0.484916 99.5476 -0.483612C99.5362 -0.481004 99.519 -0.477101 99.4963 -0.471924C99.4507 -0.46157 99.3828 -0.44612 99.2932 -0.425738C99.1139 -0.384973 98.8478 -0.324481 98.5006 -0.245576C97.806 -0.0877661 96.7866 0.143694 95.4874 0.438283C92.889 1.02746 89.1713 1.86915 84.6945 2.87919C75.7409 4.89927 63.7508 7.59266 51.6047 10.286C39.4582 12.9795 27.1569 15.6726 17.5805 17.6922C12.792 18.702 8.68704 19.5431 5.62417 20.1314C4.09241 20.4257 2.82428 20.6561 1.86293 20.8129C0.884818 20.9723 0.264216 21.0483 0 21.0483L0 22.0483Z",
            stroke: "rgba(54,94,61,1)",
            fillRule: "nonzero",
            strokeWidth: 1,
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="17.29%"
        bottom="75.95%"
        left="30.39%"
        right="50.83%"
        {...getOverrideProps(overrides, "Connector39011012")}
      ></Icon>
      <Icon
        width="87.92px"
        height="3.27px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 87.92486572265625,
          height: 3.2703514099121094,
        }}
        paths={[
          {
            d: "M0 0.5C2.48732 0.5 24.4521 1.31684 45.8183 2.13481C56.4962 2.5436 67.018 2.95239 74.8702 3.25898C78.7963 3.41227 82.0551 3.54002 84.3323 3.62944C85.4709 3.67415 86.3641 3.70928 86.9727 3.73324C87.277 3.74521 87.5101 3.75439 87.6671 3.76058C87.7457 3.76368 87.8052 3.76602 87.845 3.76759C87.865 3.76838 87.88 3.76897 87.8901 3.76937C87.8951 3.76957 87.8989 3.76971 87.9014 3.76981C87.9026 3.76986 87.9036 3.7699 87.9042 3.76993C87.9048 3.76995 87.9052 3.76996 87.9249 3.27035C87.9446 2.77074 87.9443 2.77073 87.9436 2.7707C87.943 2.77068 87.942 2.77064 87.9408 2.77059C87.9383 2.77049 87.9345 2.77034 87.9295 2.77014C87.9194 2.76975 87.9044 2.76916 87.8844 2.76837C87.8446 2.7668 87.785 2.76445 87.7065 2.76136C87.5495 2.75517 87.3163 2.74599 87.012 2.73401C86.4034 2.71006 85.5102 2.67493 84.3715 2.63021C82.0943 2.54079 78.8354 2.41304 74.9092 2.25974C67.0568 1.95314 56.5348 1.54434 45.8566 1.13554C24.5104 0.318337 2.51268 -0.5 0 -0.5L0 0.5Z",
            stroke: "rgba(125,214,232,1)",
            fillRule: "nonzero",
            strokeWidth: 1,
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="15.64%"
        bottom="83.33%"
        left="36.06%"
        right="47.38%"
        {...getOverrideProps(overrides, "Connector39011014")}
      ></Icon>
      <Icon
        width="5.14px"
        height="30px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 5.142486572265625,
          height: 30.00141143798828,
        }}
        paths={[
          {
            d: "M0 0.5C0.346254 0.5 0.76549 0.760299 1.22551 1.54258C1.67237 2.30247 2.07421 3.42088 2.42836 4.81115C3.13459 7.58353 3.61846 11.3011 3.94817 15.0446C4.27738 18.7823 4.45105 22.5225 4.54236 25.3299C4.58801 26.7332 4.61304 27.9025 4.62667 28.7206C4.63348 29.1297 4.63744 29.4509 4.63969 29.6696C4.64082 29.779 4.64152 29.8628 4.64194 29.9191C4.64215 29.9472 4.64229 29.9685 4.64237 29.9827C4.64242 29.9898 4.64245 29.9951 4.64247 29.9986C4.64248 30.0004 4.64248 30.0017 4.64249 30.0026C4.64249 30.003 4.64249 30.0033 4.64249 30.0035C4.64249 30.0037 4.64249 30.0038 5.14249 30.0014C5.64248 29.999 5.64248 29.9989 5.64248 29.9986C5.64248 29.9984 5.64248 29.998 5.64247 29.9975C5.64247 29.9965 5.64246 29.9951 5.64245 29.9932C5.64243 29.9895 5.6424 29.984 5.64235 29.9766C5.64227 29.962 5.64212 29.9402 5.64191 29.9116C5.64149 29.8544 5.64078 29.7697 5.63964 29.6593C5.63736 29.4386 5.63338 29.1153 5.62653 28.704C5.61283 27.8814 5.58767 26.7068 5.54183 25.2974C5.45018 22.4796 5.27572 18.7195 4.94431 14.9568C4.61341 11.1999 4.12415 7.41717 3.39741 4.56429C3.03508 3.14193 2.60548 1.91648 2.08751 1.03567C1.58271 0.177245 0.903746 -0.5 0 -0.5L0 0.5Z",
            stroke: "rgba(149,4,149,1)",
            fillRule: "nonzero",
            strokeWidth: 1,
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="7.22%"
        bottom="83.36%"
        left="52.33%"
        right="46.7%"
        {...getOverrideProps(overrides, "Connector39011016")}
      ></Icon>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="700"
        color="rgba(153,153,153,1)"
        lineHeight="16.94318199157715px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="22.3%"
        bottom="72.37%"
        left="78.19%"
        right="0.9%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Diversified: 27%​"
        {...getOverrideProps(overrides, "Diversified: 27%\u200B")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="700"
        color="rgba(153,153,153,1)"
        lineHeight="16.94318199157715px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="92.95%"
        bottom="1.71%"
        left="74.05%"
        right="5.42%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Consumer: 25%​"
        {...getOverrideProps(overrides, "Consumer: 25%\u200B")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="700"
        color="rgba(153,153,153,1)"
        lineHeight="16.94318199157715px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="91.69%"
        bottom="2.97%"
        left="12.81%"
        right="68.16%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Industrial: 18%​"
        {...getOverrideProps(overrides, "Industrial: 18%\u200B")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="700"
        color="rgba(153,153,153,1)"
        lineHeight="16.94318199157715px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="51.81%"
        bottom="42.85%"
        left="0.94%"
        right="78.52%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Healthcare: 11%​"
        {...getOverrideProps(overrides, "Healthcare: 11%\u200B")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="700"
        color="rgba(153,153,153,1)"
        lineHeight="16.94318199157715px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="39.25%"
        bottom="55.41%"
        left="2.26%"
        right="77.01%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Technology: 9%​"
        {...getOverrideProps(overrides, "Technology: 9%\u200B")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="700"
        color="rgba(153,153,153,1)"
        lineHeight="16.94318199157715px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="30.77%"
        bottom="63.89%"
        left="10.55%"
        right="74.94%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Energy: 5%​"
        {...getOverrideProps(overrides, "Energy: 5%\u200B")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="700"
        color="rgba(153,153,153,1)"
        lineHeight="16.94318199157715px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="22.61%"
        bottom="72.05%"
        left="15.64%"
        right="71.36%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Other: 4%​"
        {...getOverrideProps(overrides, "Other: 4%\u200B")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="700"
        color="rgba(153,153,153,1)"
        lineHeight="16.94318199157715px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="14.13%"
        bottom="80.53%"
        left="5.65%"
        right="65.71%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Financial Services: 1%​"
        {...getOverrideProps(overrides, "Financial Services: 1%\u200B")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="700"
        color="rgba(153,153,153,1)"
        lineHeight="16.94318199157715px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="5.65%"
        bottom="89.01%"
        left="30.52%"
        right="49.51%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Real Estate: 0%​"
        {...getOverrideProps(overrides, "Real Estate: 0%\u200B")}
      ></Text>
    </View>
  );
}
