/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getReports = /* GraphQL */ `
  query GetReports($id: ID!) {
    getReports(id: $id) {
      id
      report_name
      Investors {
        nextToken
        __typename
      }
      progam_portal_tag
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelReportsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        report_name
        progam_portal_tag
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContacts = /* GraphQL */ `
  query GetContacts($id: ID!) {
    getContacts(id: $id) {
      id
      contact_name
      email
      investorsID
      doc_types
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        contact_name
        email
        investorsID
        doc_types
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const contactsByInvestorsID = /* GraphQL */ `
  query ContactsByInvestorsID(
    $investorsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContactsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactsByInvestorsID(
      investorsID: $investorsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contact_name
        email
        investorsID
        doc_types
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOpportunties = /* GraphQL */ `
  query GetOpportunties($id: ID!) {
    getOpportunties(id: $id) {
      id
      fundsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOpportunties = /* GraphQL */ `
  query ListOpportunties(
    $filter: ModelOpportuntiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOpportunties(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fundsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const opportuntiesByFundsID = /* GraphQL */ `
  query OpportuntiesByFundsID(
    $fundsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOpportuntiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    opportuntiesByFundsID(
      fundsID: $fundsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fundsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvestors = /* GraphQL */ `
  query GetInvestors($id: ID!) {
    getInvestors(id: $id) {
      id
      investor_name
      fundsID
      Contacts {
        nextToken
        __typename
      }
      reportsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInvestors = /* GraphQL */ `
  query ListInvestors(
    $filter: ModelInvestorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvestors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        investor_name
        fundsID
        reportsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const investorsByFundsID = /* GraphQL */ `
  query InvestorsByFundsID(
    $fundsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvestorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    investorsByFundsID(
      fundsID: $fundsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        investor_name
        fundsID
        reportsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const investorsByReportsID = /* GraphQL */ `
  query InvestorsByReportsID(
    $reportsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvestorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    investorsByReportsID(
      reportsID: $reportsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        investor_name
        fundsID
        reportsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFunds = /* GraphQL */ `
  query GetFunds($id: ID!) {
    getFunds(id: $id) {
      id
      fund_number
      fund_name
      fund_short_name
      bank_account_name
      Opportunties {
        nextToken
        __typename
      }
      Investors {
        nextToken
        __typename
      }
      fund_manager_concat
      sector
      strategy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFunds = /* GraphQL */ `
  query ListFunds(
    $filter: ModelFundsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFunds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fund_number
        fund_name
        fund_short_name
        bank_account_name
        fund_manager_concat
        sector
        strategy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
