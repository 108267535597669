// Sidebar.js
import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { FaSignOutAlt, FaCaretDown, FaCaretUp } from "react-icons/fa";
import { clientMenu, adminMenu } from "./menuConfig";
import "./Sidebar.css"; // Import custom CSS for styling

const SidebarNew = ({ isAdmin }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const menuItems = isAdmin ? adminMenu : clientMenu;

  // Helper function to determine if a menu item or its sub-items are active
  const isMenuItemActive = (item) => {
    if (item.subItems) {
      return item.subItems.some(
        (subItem) => location.pathname === subItem.path
      );
    } else {
      return location.pathname === item.path;
    }
  };

  // Initialize open menus based on the current route
  const initialOpenMenus = {};
  menuItems.forEach((item) => {
    if (item.subItems && isMenuItemActive(item)) {
      initialOpenMenus[item.name] = true;
    }
  });

  const [openMenus, setOpenMenus] = useState(initialOpenMenus);

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  const toggleMenu = (menuName) => {
    setOpenMenus((prevState) => ({
      ...prevState,
      [menuName]: !prevState[menuName],
    }));
  };

  const renderMenuItems = (items) => {
    return items.map((item) => {
      const isActive = isMenuItemActive(item);

      if (item.subItems) {
        const isOpen = openMenus[item.name] || false;

        return (
          <div key={item.name}>
            <div
              className={`list-group-item list-group-item-action bg-light ${
                isActive ? "active" : ""
              }`}
              onClick={() => toggleMenu(item.name)}
            >
              {item.icon && <item.icon />} {item.name}{" "}
              {isOpen ? <FaCaretUp /> : <FaCaretDown />}
            </div>
            {isOpen &&
              item.subItems.map((subItem) => (
                <Nav.Link
                  as={Link}
                  key={subItem.path}
                  to={subItem.path}
                  className={`list-group-item list-group-item-action bg-light subitem ${
                    location.pathname === subItem.path ? "active" : ""
                  }`}
                >
                  {subItem.icon && <subItem.icon />} {subItem.name}
                </Nav.Link>
              ))}
          </div>
        );
      } else {
        return (
          <Nav.Link
            as={Link}
            key={item.path}
            to={item.path}
            className={`list-group-item list-group-item-action bg-light ${
              isActive ? "active" : ""
            }`}
          >
            {item.icon && <item.icon />} {item.name}
          </Nav.Link>
        );
      }
    });
  };

  return (
    <div className="d-flex" id="wrapper">
      <div className="bg-light border-right" id="sidebar-wrapper">
        <Nav className="list-group flex-column">
          {renderMenuItems(menuItems)}
          <Nav.Link
            onClick={handleLogout}
            className="list-group-item list-group-item-action bg-light mt-auto"
          >
            <FaSignOutAlt /> Log out
          </Nav.Link>
        </Nav>
      </div>
    </div>
  );
};

export default SidebarNew;
