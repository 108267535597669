// AdminApp.js
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";

import HomePage from "./HomePage Admin";
import FileUpload from "./Components/File System/Upload";
import EmailEventLog from "./Components/Email System/EmailEventLog";
import FileViewer from "./Components/File System/FileViewer";
import Settings from "./Components/Settings";
import FormWizardSample from "./Components/Email System/FormWizard";

function AdminApp() {
  const requiredGroups = ["Admins", "SuperAdmins"];

  return (
    <div className={`d-flex custom-body`} id="wrapper">
      <div>
        <Routes>
          <Route
            path="/home"
            element={
              <ProtectedRoute requiredGroups={requiredGroups}>
                <HomePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/files/upload"
            element={
              <ProtectedRoute requiredGroups={requiredGroups}>
                <FileUpload />
              </ProtectedRoute>
            }
          />
          <Route
            path="/files/view"
            element={
              <ProtectedRoute requiredGroups={requiredGroups}>
                <FileViewer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute requiredGroups={requiredGroups}>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/email/wizard"
            element={
              <ProtectedRoute requiredGroups={requiredGroups}>
                <FormWizardSample />
              </ProtectedRoute>
            }
          />
          <Route
            path="/email/audit"
            element={
              <ProtectedRoute requiredGroups={requiredGroups}>
                <EmailEventLog />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default AdminApp;
