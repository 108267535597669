import { useEffect } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

const useSessionChecker = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let intervalId;

    const checkAuthStatus = async () => {
      try {
        // Simulate session expiration by throwing an error
        // throw new Error("Simulated session expiration");

        // Original code:
        await Auth.currentSession();
        // console.log(await Auth.currentSession());
        // Session is valid
      } catch (error) {
        // Session has expired or user is not authenticated
        await Auth.signOut();
        navigate("/");
      }
    };

    // Check immediately
    checkAuthStatus();

    // Set up periodic checks (e.g., every 5 minutes)
    intervalId = setInterval(checkAuthStatus, 5 * 60 * 1000);

    // Clean up on unmount
    return () => clearInterval(intervalId);
  }, [navigate]);
};

export default useSessionChecker;
