import React, { Component } from "react";
import { API, Storage } from "aws-amplify";
import { Table, Spinner, Row, Col, Form } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import DropdownFilter from "../DropdownFilter";
import FundFilter from "../FundFilter";
// import { FileUploader } from "react-drag-drop-files";
// import * as FilePond from "filepond";

const apiName = "talentsapi";

class FileUpload extends Component {
  state = {
    selectedFiles: [],
    uploadedFileCount: 0,
    loading: false,
    fund: "",
    docType: "",
    uploadDate: new Date(), // Initialize with the current date and time
  };

  componentDidMount() {
    // Set up interval to update the time every second
    this.interval = setInterval(() => {
      this.setState({ uploadDate: new Date() });
    }, 1000);
  }

  componentWillUnmount() {
    // Clear the interval when the component unmounts
    clearInterval(this.interval);
  }

  onFileChange = (event) => {
    let files = [];

    if (event.target && event.target.files) {
      // This handles the case where event is from a standard file input
      files = Array.from(event.target.files);
    } else if (Array.isArray(event)) {
      // This handles the case where event is directly an array of files (like from FileUploader)
      files = event;
    } else if (event && event.length) {
      // Handle other array-like objects
      files = Array.from(event);
    }

    if (files.length > 0) {
      this.setState((prevState) => ({
        selectedFiles: [...prevState.selectedFiles, ...files],
      }));
      console.log("Selected files:", files);
    } else {
      console.error("No files were selected or an invalid input was provided.");
    }
  };

  removeFile = (index) => {
    const { selectedFiles } = this.state;
    selectedFiles.splice(index, 1);
    this.setState({ selectedFiles });
  };

  logBatch = async (serialID, readableTimestamp, fund, docType) => {
    const path = "/logBatch";
    const requestInfo = {
      headers: {},
      body: {
        serialID,
        readableTimestamp,
        fund,
        docType,
      },
    };

    try {
      await API.post(apiName, path, requestInfo);
      console.log("Batch logged successfully.");
    } catch (error) {
      console.error("Error logging batch:", error);
      alert("Error logging batch. Please try again later.");
    }
  };

  onFileUpload = async (event) => {
    const { selectedFiles, fund, docType, uploadDate } = this.state;

    if (selectedFiles.length === 0) {
      alert("Please select files to upload.");
      return;
    }

    if (!fund || !docType) {
      alert("Please select a fund and a document type.");
      return;
    }

    // Extract fund and docType from file names and compare with selected filters
    const mismatchedFiles = selectedFiles.filter((file) => {
      const [fileFund, fileDocType] = file.name.split("_").slice(0, 2);
      return fileFund !== fund || fileDocType !== docType;
    });

    if (mismatchedFiles.length > 0) {
      const mismatchedFileNames = mismatchedFiles
        .map((file) => file.name)
        .join(", ");
      const confirmationMessage = `Are you sure you want to upload? The following files don't match the selected fund or doc type: ${mismatchedFileNames}`;

      const proceedWithUpload = window.confirm(confirmationMessage);
      if (!proceedWithUpload) {
        return; // Abort upload if the user cancels
      }
    }

    this.setState({ loading: true });

    const serialID = uploadDate.toISOString().replace(/[-:.TZ]/g, "");
    const readableTimestamp = uploadDate.toLocaleString();

    await this.logBatch(serialID, readableTimestamp, fund, docType);

    let successfullyUploadedCount = 0;

    for (const file of selectedFiles) {
      const fileName = `${serialID}/${file.name.replace(/_/g, "/")}`;
      try {
        await Storage.put(fileName, file, {
          contentType: file.type,
          metadata: {
            fund,
            docType,
            uploadDate: uploadDate.toISOString(),
            serialID,
          },
        });
        successfullyUploadedCount++;
        console.log(`File ${fileName} uploaded successfully.`);
      } catch (error) {
        console.error(`Error uploading ${fileName}: ${error}`);
        alert(`Error uploading ${fileName}. Please try again later.`);
      }
    }

    this.setState({
      loading: false,
      selectedFiles: [],
      uploadedFileCount: successfullyUploadedCount,
    });

    if (successfullyUploadedCount === selectedFiles.length) {
      alert("All files uploaded successfully.");
    }
  };

  fileData = () => {
    const { selectedFiles, uploadedFileCount } = this.state;

    if (selectedFiles.length > 0) {
      return (
        <div className="table-container">
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <th>File Details</th>
                <th style={{ textAlign: "right", width: "1%" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {selectedFiles.map((file, index) => (
                <tr key={index}>
                  <td>
                    {file.name} ({(file.size / 1024 / 1024).toFixed(2)} MB)
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <button onClick={() => this.removeFile(index)}>
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      );
    } else if (uploadedFileCount > 0) {
      return (
        <div>
          <h4 style={{ color: "var(--text-color)" }}>
            {uploadedFileCount === 1 ? "File" : "Files"} uploaded successfully.
          </h4>
        </div>
      );
    }
  };

  handleFundSelect = (selectedFund) => {
    this.setState({ fund: selectedFund });
  };

  handleDocTypeSelect = (selectedDocType) => {
    this.setState({ docType: selectedDocType });
  };

  render() {
    const { selectedFiles, loading, uploadDate } = this.state;

    const docTypeOptions = ["CAS", "QFS", "Call", "Dist", "QLPR", "Tax"];

    return (
      <div className="master-container">
        <div className="file-upload-container">
          <Row>
            <Col>
              <FundFilter
                placeholder="All Funds"
                onSelect={this.handleFundSelect}
              />
            </Col>
            <Col>
              <DropdownFilter
                options={docTypeOptions}
                onSelect={this.handleDocTypeSelect}
                placeholder="Select Document Type"
              />
            </Col>
            <Col>
              <Form.Group controlId="uploadDate">
                <div title="This field is automatic and can't be changed.">
                  <DatePicker
                    selected={uploadDate}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className="form-control"
                    disabled
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          {/* <FilePond /> */}
          {/* <FileUploader
            classes="upload-box"
            type="file"
            id="fileInput"
            multiple={true}
            handleChange={this.onFileChange}
          /> */}
          {/* <FaFileUpload />
          Drag and Drop File Placeholder */}
          <div className="button-group">
            <label htmlFor="fileInput" className="choose-files-button button">
              Choose Files
            </label>
            <input
              type="file"
              id="fileInput"
              className="file-input"
              multiple
              onChange={this.onFileChange}
            />
            <button className="button" onClick={this.onFileUpload}>
              Upload
            </button>
            <div className="stats">
              <p>
                {selectedFiles.length} file
                {selectedFiles.length !== 1 ? "s" : ""} selected
              </p>
            </div>
          </div>
          {loading ? (
            <div style={{ textAlign: "center", margin: "20px" }}>
              <Spinner animation="border" />
              <p>Uploading Files...</p>
            </div>
          ) : (
            this.fileData()
          )}
        </div>
      </div>
    );
  }
}

export default FileUpload;
