import { React } from "react";

const Settings = ({ isAuthenticated }) => {
  return (
    <div className="master-container">
      <h1>This is the settings page</h1>
    </div>
  );
};

export default Settings;
