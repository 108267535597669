/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon, Text, View } from "@aws-amplify/ui-react";
export default function FundedVerticalBarCard(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="634px"
      height="427px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      borderRadius="12px"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,0)"
      {...getOverrideProps(overrides, "FundedVerticalBarCard")}
      {...rest}
    >
      <View
        padding="0px 0px 0px 0px"
        width="500px"
        height="302px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="63px"
        left="67px"
        {...getOverrideProps(overrides, "Vertical Bar chart")}
      >
        <View
          width="500px"
          height="300px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="0px"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Rectangle")}
        ></View>
        <Icon
          width="0px"
          height="279px"
          viewBox={{ minX: 0, minY: 0, width: 1, height: 279 }}
          paths={[]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="62.5px"
          {...getOverrideProps(overrides, "X-axis grid39011182")}
        ></Icon>
        <Icon
          width="0px"
          height="279px"
          viewBox={{ minX: 0, minY: 0, width: 1, height: 279 }}
          paths={[]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="187.5px"
          {...getOverrideProps(overrides, "X-axis grid39011184")}
        ></Icon>
        <Icon
          width="0px"
          height="279px"
          viewBox={{ minX: 0, minY: 0, width: 1, height: 279 }}
          paths={[]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="312.5px"
          {...getOverrideProps(overrides, "X-axis grid39011186")}
        ></Icon>
        <Icon
          width="0px"
          height="279px"
          viewBox={{ minX: 0, minY: 0, width: 1, height: 279 }}
          paths={[]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="437.5px"
          {...getOverrideProps(overrides, "X-axis grid39011188")}
        ></Icon>
        <Icon
          width="500px"
          height="0px"
          viewBox={{ minX: 0, minY: 0, width: 500, height: 1 }}
          paths={[]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="279.5px"
          left="0px"
          {...getOverrideProps(overrides, "Y-axis grid39011190")}
        ></Icon>
        <Icon
          width="500px"
          height="0px"
          viewBox={{ minX: 0, minY: 0, width: 500, height: 1 }}
          paths={[]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="223.5px"
          left="0px"
          {...getOverrideProps(overrides, "Y-axis grid39011192")}
        ></Icon>
        <Icon
          width="500px"
          height="0px"
          viewBox={{ minX: 0, minY: 0, width: 500, height: 1 }}
          paths={[]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="167.5px"
          left="0px"
          {...getOverrideProps(overrides, "Y-axis grid39011194")}
        ></Icon>
        <Icon
          width="500px"
          height="0px"
          viewBox={{ minX: 0, minY: 0, width: 500, height: 1 }}
          paths={[]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="112.5px"
          left="0px"
          {...getOverrideProps(overrides, "Y-axis grid39011196")}
        ></Icon>
        <Icon
          width="500px"
          height="0px"
          viewBox={{ minX: 0, minY: 0, width: 500, height: 1 }}
          paths={[]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="56.5px"
          left="0px"
          {...getOverrideProps(overrides, "Y-axis grid39011198")}
        ></Icon>
        <Icon
          width="500px"
          height="0px"
          viewBox={{ minX: 0, minY: 0, width: 500, height: 1 }}
          paths={[]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0.5px"
          left="0px"
          {...getOverrideProps(overrides, "Y-axis grid39011200")}
        ></Icon>
        <Icon
          width="500px"
          height="0px"
          viewBox={{ minX: 0, minY: 0, width: 500, height: 1 }}
          paths={[]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="279px"
          left="0px"
          {...getOverrideProps(overrides, "X-axis")}
        ></Icon>
        <Icon
          width="0px"
          height="279px"
          viewBox={{ minX: 0, minY: 0, width: 1, height: 279 }}
          paths={[]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="0px"
          {...getOverrideProps(overrides, "Y-axis")}
        ></Icon>
        <Text
          fontFamily="Inter"
          fontSize="12px"
          fontWeight="700"
          color="rgba(163,163,163,1)"
          lineHeight="14.522727012634277px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="287px"
          left="48.5px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="10T I"
          {...getOverrideProps(overrides, "10T I")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="12px"
          fontWeight="700"
          color="rgba(163,163,163,1)"
          lineHeight="14.522727012634277px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="287px"
          left="171.5px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="10T II"
          {...getOverrideProps(overrides, "10T II")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="12px"
          fontWeight="700"
          color="rgba(163,163,163,1)"
          lineHeight="14.522727012634277px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="287px"
          left="294.5px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="10T III"
          {...getOverrideProps(overrides, "10T III")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="12px"
          fontWeight="700"
          color="rgba(163,163,163,1)"
          lineHeight="14.522727012634277px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="287px"
          left="418.5px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="10T IV"
          {...getOverrideProps(overrides, "10T IV")}
        ></Text>
        <Icon
          width="85px"
          height="246px"
          viewBox={{ minX: 0, minY: 0, width: 85, height: 246 }}
          paths={[
            {
              d: "M77 0L8 0C3.58172 0 0 3.58173 0 8.00001L0 238C0 242.418 3.58172 246 8 246L77 246C81.4183 246 85 242.418 85 238L85 8C85 3.58172 81.4183 0 77 0Z",
              fill: "rgba(54,94,61,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="33px"
          left="19px"
          {...getOverrideProps(overrides, "Bar39011209")}
        ></Icon>
        <Icon
          width="85px"
          height="190px"
          viewBox={{ minX: 0, minY: 0, width: 85, height: 190 }}
          paths={[
            {
              d: "M77 0L8 0C3.58172 0 0 3.58172 0 8L0 182C0 186.418 3.58172 190 8 190L77 190C81.4183 190 85 186.418 85 182L85 8C85 3.58172 81.4183 0 77 0Z",
              fill: "rgba(125,214,232,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="89px"
          left="144px"
          {...getOverrideProps(overrides, "Bar39011210")}
        ></Icon>
        <Icon
          width="85px"
          height="145px"
          viewBox={{ minX: 0, minY: 0, width: 85, height: 145 }}
          paths={[
            {
              d: "M77 0L8 0C3.58172 0 0 3.58173 0 8L0 137C0 141.418 3.58172 145 8 145L77 145C81.4183 145 85 141.418 85 137L85 8C85 3.58172 81.4183 0 77 0Z",
              fill: "rgba(149,4,149,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="134px"
          left="269px"
          {...getOverrideProps(overrides, "Bar39011211")}
        ></Icon>
        <Icon
          width="85px"
          height="156px"
          viewBox={{ minX: 0, minY: 0, width: 85, height: 156 }}
          paths={[
            {
              d: "M77 0L8 0C3.58172 0 0 3.58172 0 8L0 148C0 152.418 3.58172 156 8 156L77 156C81.4183 156 85 152.418 85 148L85 8C85 3.58172 81.4183 0 77 0Z",
              fill: "rgba(77,0,77,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="123px"
          left="394px"
          {...getOverrideProps(overrides, "Bar39011212")}
        ></Icon>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(153,153,153,1)"
          lineHeight="16.94318199157715px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="13px"
          left="45px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="110%​"
          {...getOverrideProps(overrides, "110%\u200B")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(153,153,153,1)"
          lineHeight="16.94318199157715px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="69px"
          left="173px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="85%​"
          {...getOverrideProps(overrides, "85%\u200B")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(153,153,153,1)"
          lineHeight="16.94318199157715px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="114px"
          left="298px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="65%​"
          {...getOverrideProps(overrides, "65%\u200B")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(153,153,153,1)"
          lineHeight="16.94318199157715px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="103px"
          left="423px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="70%​"
          {...getOverrideProps(overrides, "70%\u200B")}
        ></Text>
      </View>
    </View>
  );
}
