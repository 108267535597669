import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faChartLine,
//   faLightbulb,
//   faDollarSign,
//   faFileAlt,
// } from "@fortawesome/free-solid-svg-icons";
// import { Pie } from "react-chartjs-2";
import mapPlaceholder from "./map.png"; // Adjust the path based on your project structure
import "./Components/Dashboard.css"; // Import the CSS file

const HomePage = () => {
  // const sectorData = {
  //   labels: ["Technology", "Healthcare", "Finance", "Energy", "Consumer"],
  //   datasets: [
  //     {
  //       data: [30, 20, 25, 10, 15],
  //       backgroundColor: [
  //         "#FF6384",
  //         "#36A2EB",
  //         "#FFCE56",
  //         "#4BC0C0",
  //         "#9966FF",
  //       ],
  //     },
  //   ],
  // };

  // const industryData = {
  //   labels: ["Software", "Biotech", "Banking", "Oil & Gas", "Retail"],
  //   datasets: [
  //     {
  //       data: [25, 25, 20, 15, 15],
  //       backgroundColor: [
  //         "#FF6384",
  //         "#36A2EB",
  //         "#FFCE56",
  //         "#4BC0C0",
  //         "#9966FF",
  //       ],
  //     },
  //   ],
  // };

  return (
    <div>
      <div className="master-container">
        <div className="portfolio">
          {/* <h2>MY PORTFOLIO SAMPLE DASHBOARD</h2> */}
          <div className="welcome-box">
            <h3>WELCOME GARNETT</h3>
            <p>Investors:</p>
            <p>Sample Investor Name</p>
            <p>Investment Entity LLC</p>
            <p className="founding-member">FOUNDING MEMBER</p>
          </div>
          <div className="stats">
            <div className="stat">
              <p>Total Contributed</p>
              <h3>$2.45m</h3>
            </div>
            <div className="stat">
              <p>Total Distributed</p>
              <h3>$575k</h3>
            </div>
            <div className="stat">
              <p>Number of Investments</p>
              <h3>9</h3>
            </div>
            <div className="stat">
              <p>Underlying Funds</p>
              <h3>12</h3>
            </div>
          </div>
          <div className="charts">
            <div className="chart">
              <h4>Sector Exposure</h4>
              {/* <Pie data={sectorData} /> */}
            </div>
            <div className="chart">
              <h4>Industry Exposure</h4>
              {/* <Pie data={industryData} /> */}
            </div>
          </div>
        </div>
        <div>
          <div className="capital-calls">
            <h4>Upcoming Capital Calls</h4>
            <div className="call">
              <p>Capital Call 3 - 2.28.21</p>
              <p>10Talents IV</p>
              <p>Due Date: Feb 28, 2021</p>
            </div>
            <div className="call">
              <p>Capital Call 2 - 2.27.21</p>
              <p>10Talents III</p>
              <p>Due Date: Feb 27, 2021</p>
            </div>
          </div>
          <div className="portfolio-geography">
            <h4>Portfolio Geography</h4>
            <img
              src={mapPlaceholder}
              alt="Map Placeholder"
              className="map-placeholder"
            />
          </div>
          <div className="funds">
            <h4>My Funds</h4>
            <table>
              <thead>
                <tr>
                  <th>Fund</th>
                  <th>Date</th>
                  <th>Contributions</th>
                  <th>Distributions</th>
                  <th>Est. Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>10Talents I (Castle Creek)</td>
                  <td>May 4, 2021</td>
                  <td>$500,000</td>
                  <td>$87,450</td>
                  <td>$575,551</td>
                </tr>
                <tr>
                  <td>10Talents II (Strattam)</td>
                  <td>May 4, 2021</td>
                  <td>$600,000</td>
                  <td>$35,450</td>
                  <td>$575,551</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="news-feed">
            <h4>10T News Feed</h4>
            <div className="news-item">
              <p>Manager: AEA INVESTORS, Vehicle: VI BLUE I</p>
              <p>Feb 21, 2021</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
