import React from "react";
import { Authenticator } from "@aws-amplify/ui-react";

function LoginPage(props) {
  const changeOfState = () => {
    console.log("the state hath changed milord");
  };

  return (
    <div>
      <Authenticator textAlign="center" hideSignUp onSubmit={changeOfState} />
    </div>
  );
}

export default LoginPage;
