import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";

import {
  Table,
  Row,
  Col,
  Placeholder,
  Modal,
  Form,
  Button,
} from "react-bootstrap";
import {
  FaSort,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
  FaEdit,
  FaCopy,
} from "react-icons/fa";
import { BsBoxArrowInUpRight } from "react-icons/bs";
import DropdownFilter from "./DropdownFilter";
import ActionDropdown from "./ActionDropdown";

const GenericTable = ({
  data,
  headers,
  loading,
  keyField,
  visibleColumns,
  filterableColumns,
  onRowSelect,
  actionStates,
  serialID,
  dropdownContext,
}) => {
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState("serialID");
  const [sortedData, setSortedData] = useState([...data]);
  const [selectedList, setSelectedList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [filters, setFilters] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [editField, setEditField] = useState(null);
  const [editValue, setEditValue] = useState("");
  const [editKey, setEditKey] = useState("");
  const [columnWidths, setColumnWidths] = useState({});

  const totalItems = sortedData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const visibleHeaders = visibleColumns ? visibleColumns : headers;
  const filterables = filterableColumns ? filterableColumns : headers;

  useEffect(() => {
    const filteredData = filterData(data); // Apply filters
    sortData(sortedColumn, sortOrder, filteredData); // Sort the filtered data
  }, [sortedColumn, sortOrder, data, filters]);

  const sortData = (column, order, dataToSort) => {
    const sorted = [...dataToSort].sort((a, b) => {
      let aKey = a[column];
      let bKey = b[column];

      if (column === "serialID") {
        return order === "asc" ? aKey - bKey : bKey - aKey;
      } else {
        return order === "asc"
          ? aKey.localeCompare(bKey)
          : bKey.localeCompare(aKey);
      }
    });
    setSortedData(sorted);
  };

  const handleSort = (column) => {
    const newSortOrder =
      sortedColumn === column && sortOrder === "asc" ? "desc" : "asc";
    setSortedColumn(column);
    setSortOrder(newSortOrder);
  };

  const handleRowSelection = (row) => {
    const isSelected = selectedList.some(
      (selectedRow) => selectedRow[keyField] === row[keyField]
    );

    const newSelectedList = isSelected
      ? selectedList.filter(
          (selectedRow) => selectedRow[keyField] !== row[keyField]
        )
      : [...selectedList, row];

    setSelectedList(newSelectedList);

    if (onRowSelect) {
      onRowSelect(newSelectedList);
    }
  };

  const handleSelectAll = () => {
    if (selectedList.length === sortedData.length) {
      setSelectedList([]);
    } else {
      setSelectedList(sortedData);
    }
  };

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleFilterChange = (column, selectedValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [column]: selectedValue,
    }));
  };

  const filterData = (data) => {
    return data.filter((row) => {
      return Object.keys(filters).every((column) => {
        if (!filters[column]) return true;
        return row[column].toString().includes(filters[column]);
      });
    });
  };

  const openEditModal = (field, value, key) => {
    setEditField(field);
    console.log("field", field);
    setEditValue(value);
    console.log("value", value);
    setEditKey(key);
    console.log("key", key);
    setShowEditModal(true);
  };

  const saveEdit = async () => {
    const apiName = "talentsapi";
    const path = "/updateBatch"; // Modify paths as necessary
    const body = { serialID: editKey }; // Use the key from state

    if (editField === "fund") {
      body.fund = editValue;
    } else if (editField === "docType") {
      body.docType = editValue;
    }

    try {
      const init = {
        body,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await API.post(apiName, path, init);
      console.log("Update response:", response);

      // Update the row's value in the state
      setSortedData((prevData) =>
        prevData.map((row) =>
          row[keyField] === editKey ? { ...row, [editField]: editValue } : row
        )
      );

      setShowEditModal(false); // Close the modal
    } catch (error) {
      console.error("Error updating item:", error);
      alert("Error updating item. Please try again later.");
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        alert("Copied to clipboard!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const startResizing = (e, header) => {
    e.stopPropagation(); // Prevent triggering the sorting event
    const startX = e.clientX;
    const startWidth = columnWidths[header] || 100; // Default width

    const onMouseMove = (moveEvent) => {
      const newWidth = Math.max(15, startWidth + moveEvent.clientX - startX); // ENSURE MINIMUM WIDTH IS 15PX

      setColumnWidths((prevWidths) => ({
        ...prevWidths,
        [header]: newWidth,
      }));
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const renderHeader = () => (
    <thead>
      <tr>
        <th>
          <input
            type="checkbox"
            checked={selectedList.length === sortedData.length}
            onChange={handleSelectAll}
            style={{ accentColor: "var(--secondary-color)" }}
          />
        </th>
        {visibleHeaders.map((header, index) => (
          <th
            key={index}
            style={{
              position: "relative",
              paddingRight: "10px", // ADJUST PADDING TO PROVIDE SPACE FOR SORT ICON
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              minWidth: "60px", // SET MINIMUM WIDTH TO ENSURE TEXT IS NOT COMPLETELY HIDDEN
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: columnWidths[header] || 60,
                cursor: "pointer",
                minWidth: "100px", // ENSURE MINIMUM WIDTH IS APPLIED TO THE ENTIRE HEADER CONTAINER
              }}
              onClick={() => handleSort(header)}
            >
              <span
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  // marginRight: "auto", // ALLOW TEXT TO USE AVAILABLE SPACE
                  paddingRight: "5px", // SPACE BETWEEN TEXT AND SORT ICON
                }}
              >
                {header}
              </span>
              <FaSort
                style={{
                  position: "absolute", // FIX POSITION OF SORT ICON
                  right: "5px", // ALIGN SORT ICON TO THE RIGHT SIDE
                  paddingRight: "5px", // SPACE BETWEEN TEXT AND SORT ICON
                  color:
                    header === sortedColumn
                      ? "var(--secondary-color)"
                      : "var(--primary-color)",
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                height: "100%",
                width: "10px",
                cursor: "col-resize",
                zIndex: 1, // Ensure it's on top of the sort area
              }}
              onMouseDown={(e) => startResizing(e, header)}
              onClick={(e) => e.stopPropagation()} // Prevent sort on resize click
            />
          </th>
        ))}
      </tr>
    </thead>
  );

  const renderRows = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentRows = sortedData.slice(startIndex, startIndex + itemsPerPage);

    return (
      <tbody>
        {currentRows.map((row) => (
          <tr
            key={row[keyField]}
            onClick={() => handleRowSelection(row)}
            style={{
              border: selectedList.some(
                (selectedRow) => selectedRow[keyField] === row[keyField]
              )
                ? "2px solid var(--secondary-color)"
                : "",
              cursor: "pointer",
            }}
          >
            <td onClick={(e) => e.stopPropagation()} style={{ width: "40px" }}>
              <input
                type="checkbox"
                checked={selectedList.some(
                  (selectedRow) => selectedRow[keyField] === row[keyField]
                )}
                onChange={() => handleRowSelection(row)}
                style={{ accentColor: "var(--secondary-color)" }}
              />
            </td>
            {visibleHeaders.map((header, colIndex) => (
              <td
                key={colIndex}
                style={{ width: columnWidths[header] }}
                className="table-cell"
              >
                {isValidUrl(row[header]) ? (
                  <a
                    href={row[header]}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <BsBoxArrowInUpRight />
                  </a>
                ) : (
                  <>
                    {row[header]}
                    {dropdownContext === "batch" &&
                      ["fund", "docType"].includes(header) && (
                        <div className="table-cell-buttons">
                          <FaCopy
                            className="icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              copyToClipboard(row[header]);
                            }}
                          />
                          <FaEdit
                            className="icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              openEditModal(header, row[header], row[keyField]); // Pass row[keyField] as the key
                            }}
                          />
                        </div>
                      )}
                  </>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  };

  const renderPlaceholder = () => (
    <tbody>
      {[...Array(itemsPerPage)].map((_, rowIndex) => (
        <tr key={rowIndex}>
          <td>
            <Placeholder as="div" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </td>
          {visibleHeaders.map((_, colIndex) => (
            <td key={colIndex}>
              <Placeholder as="div" animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );

  const renderPagination = () => {
    const pageNumbers = [];
    const maxVisiblePages = 3;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 2) {
        pageNumbers.push(1, 2, 3);
        if (totalPages > 3) {
          pageNumbers.push("...");
          pageNumbers.push(totalPages);
        }
      } else if (currentPage >= totalPages - 1) {
        pageNumbers.push(1);
        if (totalPages > 3) {
          pageNumbers.push("...");
        }
        pageNumbers.push(totalPages - 2, totalPages - 1, totalPages);
      } else {
        pageNumbers.push(1);
        if (currentPage > 3) {
          pageNumbers.push("...");
        }
        pageNumbers.push(currentPage - 1, currentPage, currentPage + 1);
        if (currentPage < totalPages - 2) {
          pageNumbers.push("...");
        }
        pageNumbers.push(totalPages);
      }
    }

    return (
      <div className="pagination">
        <button
          onClick={() => handlePaginationClick(1)}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          <FaAngleDoubleLeft />
        </button>
        <button
          onClick={() => handlePaginationClick(currentPage - 1)}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          <FaAngleLeft />
        </button>
        {pageNumbers.map((number, index) => (
          <button
            key={index}
            onClick={() => handlePaginationClick(number)}
            disabled={number === "..."}
            className="pagination-button"
            style={{
              backgroundColor:
                number === currentPage ? "var(--secondary-color)" : "",
            }}
          >
            {number}
          </button>
        ))}
        <button
          onClick={() => handlePaginationClick(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          <FaAngleRight />
        </button>
        <button
          onClick={() => handlePaginationClick(totalPages)}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          <FaAngleDoubleRight />
        </button>
      </div>
    );
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  return (
    <div>
      <Row className="filters-container mb-3">
        {filterables.map((header, index) => (
          <Col key={index}>
            <DropdownFilter
              options={[...new Set(data.map((row) => row[header]))]}
              onSelect={(value) => handleFilterChange(header, value)}
              placeholder={`All ${header}s`}
            />
          </Col>
        ))}
      </Row>
      <Row>
        <Col style={{ textAlign: "left" }}>
          <h3 style={{ color: "var(--text-color)" }}>
            Total Records: {totalItems}
          </h3>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <ActionDropdown
            dropdownContext={dropdownContext}
            selectedList={selectedList}
            headers={headers}
            serialIDArray={serialID}
            actionStates={actionStates}
            onActionSelect={(action, selectedList) => {
              console.log(
                `Performing ${action} on selected items:`,
                selectedList
              );
            }}
          />
        </Col>
      </Row>
      <Row>
        <Table striped bordered hover variant="dark" responsive>
          {!loading && headers.length > 0 && renderHeader()}
          {loading ? renderPlaceholder() : renderRows()}
        </Table>
      </Row>
      {totalPages > 1 && renderPagination()}

      {/* Edit Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit {editField}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>{editField}</Form.Label>
              <Form.Control
                type="text"
                value={editValue}
                onChange={(e) => setEditValue(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={saveEdit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GenericTable;
