// App.js
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Amplify, Auth, Hub } from "aws-amplify";
import awsExports from "./aws-exports";

import useSessionChecker from "./useSessionChecker";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@aws-amplify/ui-react/styles.css";

import LoginPage from "./AuthPages/LoginPage";
import AdminApp from "./AdminApp";
import ClientApp from "./ClientApp";
import Sidebar from "./Sidebar";
import SidebarNew from "./Sidebar copy";

Amplify.configure(awsExports);

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useSessionChecker();

  useEffect(() => {
    document.body.classList.add("custom-body");
    return () => {
      document.body.classList.remove("custom-body");
    };
  }, []);

  useEffect(() => {
    const fetchUserGroups = async () => {
      try {
        const session = await Auth.currentSession();
        const idToken = session.getIdToken();
        const groups = idToken.payload["cognito:groups"] || [];
        setUserGroups(groups);
        const adminStatus =
          groups.includes("Admins") || groups.includes("SuperAdmins");
        setIsAdmin(adminStatus);
        return adminStatus;
      } catch (error) {
        console.error("Error fetching user groups:", error);
        setUserGroups([]);
        setIsAdmin(false);
        return false;
      }
    };

    Auth.currentAuthenticatedUser()
      .then(() => {
        setIsAuthenticated(true);
        fetchUserGroups();
      })
      .catch(() => {
        setIsAuthenticated(false);
        navigate("/");
      });

    const listener = Hub.listen("auth", ({ payload: { event } }) => {
      switch (event) {
        case "signIn":
          setIsAuthenticated(true);
          fetchUserGroups().then((adminStatus) => {
            if (adminStatus) {
              navigate("/home");
            } else {
              navigate("/home");
            }
          });
          break;
        case "signOut":
          setIsAuthenticated(false);
          setUserGroups([]);
          setIsAdmin(false);
          navigate("/");
          break;
        default:
          break;
      }
    });

    return () => {
      listener();
    };
  }, [navigate]);

  return (
    <div className={`d-flex custom-body`} id="wrapper">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      {isAuthenticated && <SidebarNew isAdmin={isAdmin} />}
      {/* {isAuthenticated && <Sidebar isAuthenticated={isAuthenticated} />} */}
      {!isAuthenticated && <LoginPage />}
      {isAuthenticated && (isAdmin ? <AdminApp /> : <ClientApp />)}
    </div>
  );
}

export default App;
