/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://i3c6aeol5zebbd7vk6o6gh47ny.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-pcbz7enf4zagrob4rf2osbskdi",
    "aws_cloud_logic_custom": [
        {
            "name": "talentsapi",
            "endpoint": "https://yqqtyofvze.execute-api.us-east-2.amazonaws.com/staging",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-2:30f4b29c-7f19-406f-ad75-dcc98f5fa3f4",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_9taAOXWrY",
    "aws_user_pools_web_client_id": "6sbsuer9v3tclbvcv4l3hleinv",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "talentsamplifystorage192816-staging",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
